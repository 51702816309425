import { ReactNode } from 'react';
import { TranslationsProvider } from './Translations';
interface Props {
  children?: ReactNode;
}

const Providers = ({ children }: Props): JSX.Element => {
  return (
    <TranslationsProvider>
        {children}
    </TranslationsProvider>
  );
};

export default Providers;
