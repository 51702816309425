import { SyntheticEvent } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import LogoSmaller from "../../../assets/fruvin-small-logo.png";

const Wrapper = styled.div<{ showMenu: boolean }>`
  display: none;
  z-index: 99999;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  transition: all 1s;
  opacity: 0;

  img {
    padding-bottom: 30px;
  }

  ${(props) =>
    props.showMenu && `
      display: flex;
      opacity: 1;
  `}
`;

const NavButton = styled.button`
  border: none;
  background: transparent;
  color: white;
  cursor: pointer;
  font-size: 23px;
  line-height: 20px;
  text-transform: uppercase;
  padding: 20px;
`;

const Overlay = styled.div<{ showMenu: boolean }>`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: black;
  z-index: 99999;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  opacity: 0;
  width: 0;

  ${(props) =>
    props.showMenu && `
      opacity: 0.95;
      width: 100%;
  `}
`;

interface NavigationMobileProps {
  show: boolean;
  onShowChange: () => void;
}
const NavigationMobile = ({show, onShowChange}: NavigationMobileProps): JSX.Element => {
  const intl = useIntl();
  const navigate = useNavigate();

  const handleClick = (e: SyntheticEvent) => {
    document.body.classList.remove("no-scrolling");
    onShowChange();
    navigate((e.target as HTMLButtonElement).name);
  };

  return (
    <>
      <Overlay showMenu={show} />
      <Wrapper showMenu={show}>
        <img src={LogoSmaller} alt="logo" onClick={handleClick}/>
        <NavButton name={"trisunca"} onClick={handleClick}>
          {intl.formatMessage({
            id: "navigation__trisunca",
            defaultMessage: "Missing translation",
          })}
        </NavButton>
        <NavButton name={"quet"} onClick={handleClick}>
          {intl.formatMessage({
            id: "navigation__quet",
            defaultMessage: "Missing translation",
          })}
        </NavButton>
        <NavButton name={"fruskac"} onClick={handleClick}>
          {intl.formatMessage({
            id: "navigation__fruskac",
            defaultMessage: "Missing translation",
          })}
        </NavButton>
        <NavButton name={"map"} onClick={handleClick}>
          {intl.formatMessage({
            id: "navigation__map",
            defaultMessage: "Missing translation",
          })}
        </NavButton>
        <NavButton name={"about"} onClick={handleClick}>
          {intl.formatMessage({
            id: "navigation__about",
            defaultMessage: "Missing translation",
          })}
        </NavButton>
        <NavButton name={"contact"} onClick={handleClick}>
          {intl.formatMessage({
            id: "navigation__contact",
            defaultMessage: "Missing translation",
          })}
        </NavButton>
      </Wrapper>
    </>
  );
};

export default NavigationMobile;
