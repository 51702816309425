import React, { ChangeEvent, useContext } from 'react';
import styled from 'styled-components';
import breakpoints from '../../../breakpoints';
import { LanguageCode, TranslatedTheme } from '../../../providers/Translations';

interface Props {
  name?: string;
}

const SelectWrapper = styled.div<Props>`
  z-index: 99999;
  display: flex;
  align-items: center;
  position: fixed;
  right: 5%;
  top: 40px;

  &:after {
    content: ' ';
    width: 8px;
    height: 8px;
    border-right: 2px #D4AF37 solid;
    border-bottom: 2px #D4AF37 solid;
    transform: rotate(45deg);
    pointer-events: none;
    display: block;
    margin: -2px 0 5% 0.1em;

    @media only screen and (min-width: ${breakpoints.md}) {
      margin-left: 7px;
    }
  }
`;

const Select = styled.select`
  background: transparent;
  font-family: inherit;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 22px;
  color: white;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  transition: background .2s ease;

  &:focus {
    outline: none;
  }
`;

const LanguageDropdown = ({ name = 'language' }: Props): JSX.Element => {
  const { currentLanguage, languages, setLanguage } = useContext(TranslatedTheme);

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setLanguage(event.target.value as LanguageCode);
  };

  return (
    <SelectWrapper>
      <Select defaultValue={currentLanguage} name={name} onChange={handleChange} aria-label="language">
        {languages.map((language) =>
          <option key={language} value={language}>{language === 'zh-CN' ? 'CN' : language.toUpperCase()}</option>
        )}
      </Select>
    </SelectWrapper>
  );
};

export default LanguageDropdown;
