import { SyntheticEvent, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Logo from "../../../assets/fruvin-logo.png";
import breakpoints from "../../../breakpoints";
import LanguageDropdown from "../../molecules/LanguageDropdown/LanguageDropdown";
import BurgerMenu from "../Menu/BurgerMenu";
import NavigationMobile from "../NavigationMobile/NavigationMobile";

const Wrapper = styled.div<{ isScrolled: boolean }>`
  background-color: #252121;
  position: sticky;
  top: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  padding: 15px;

  img {
    display: block;
    cursor: pointer;
  }

  @media only screen and (min-width: ${breakpoints.xxsm}) {
    padding: 15px 5% 15px 5%;
  }

  @media only screen and (min-width: ${breakpoints.md}) {
    justify-content: normal;
  }
`;

const ScrollToTop = styled.div`
  z-index: 999;
  cursor: pointer;
  width: 30px;
	height: 30px;
	box-sizing: border-box;
	position: fixed;
  bottom: 2rem;
  right: 2rem;
	transform: rotate(-45deg);
  cursor: pointer;
  display: none;

	&::before {
		content: '';
		width: 100%;
		height: 100%;
		border-width: 5px 5px 0 0;
		border-style: solid;
		border-color: #D4AF37;
		transition: .2s ease;
		display: block;
		transform-origin: 100% 0;
	}


	&:after {
		content: '';
		float: left;
		position: relative;
		top: -100%;
		width: 100%;
		height: 100%;
		border-width: 0 5px 0 0;
		border-style: solid;
		border-color: #D4AF37;
		transform-origin: 100% 0;
		transition:.2s ease;
	}

	&:hover::after {
		transform: rotate(45deg);
		border-color: #D4AF37;
		height: 120%;
	}
	&:hover::before {
		border-color: #D4AF37;
		transform: scale(.8);
	}

  @media only screen and (min-width: ${breakpoints.md}) {
    display: block;
  }
`;

const LogoStyled = styled.img`
	width: 8rem;
	height: 4.5rem;

  @media only screen and (min-width: ${breakpoints.md}) {
    padding-right: 15px;
  }
`;

const NavButton = styled.button<{ isScrolled: boolean }>`
  display: none;
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 13px;
  letter-spacing: 1px;
  line-height: 20px;
  text-transform: uppercase;
  font-weight: bold;
  color: white;

  @media only screen and (min-width: ${breakpoints.md}) {
    display: block;
  }

  @media only screen and (min-width: ${breakpoints.xxxl}) {
    font-size: 14px;
  }
`;

const Header = (): JSX.Element => {
  const navigate = useNavigate();
  const intl = useIntl();
  const [isScrolled, setIsScrolled] = useState(false);
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const handleLogoClick = () => {
    navigate("");
  };

  const handleScrollToTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const handleScroll = () => {
    const isScrolled = document.body.getBoundingClientRect().top < 0;
    setIsScrolled(isScrolled);
    document.body.getBoundingClientRect().top < -300
      ? setShowScrollToTop(true)
      : setShowScrollToTop(false);
  };

  const handleClick = (e: SyntheticEvent) => {
    navigate((e.target as HTMLButtonElement).name);
  };

  const handleMenuClick = () => {
    showMenu ? document.body.classList.remove("no-scrolling") : document.body.classList.add("no-scrolling");
    setShowMenu(!showMenu);
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <BurgerMenu open={showMenu} onClick={handleMenuClick}/>
      <NavigationMobile show={showMenu} onShowChange={() => setShowMenu(!showMenu)}/>
      <Wrapper id="header" isScrolled={isScrolled}>
        <LogoStyled src={Logo} alt="logo" onClick={handleLogoClick} />
        <NavButton isScrolled={isScrolled} name={"trisunca"} onClick={handleClick}>
          {intl.formatMessage({
            id: "navigation__trisunca",
            defaultMessage: "Missing translation",
          })}
        </NavButton>
        <NavButton isScrolled={isScrolled} name={"quet"} onClick={handleClick}>
          {intl.formatMessage({
            id: "navigation__quet",
            defaultMessage: "Missing translation",
          })}
        </NavButton>
        <NavButton
          isScrolled={isScrolled}
          name={"fruskac"}
          onClick={handleClick}
        >
          {intl.formatMessage({
            id: "navigation__fruskac",
            defaultMessage: "Missing translation",
          })}
        </NavButton>
        <NavButton isScrolled={isScrolled} name={"map"} onClick={handleClick}>
          {intl.formatMessage({
            id: "navigation__map",
            defaultMessage: "Missing translation",
          })}
        </NavButton>
        <NavButton isScrolled={isScrolled} name={"about"} onClick={handleClick}>
          {intl.formatMessage({
            id: "navigation__about",
            defaultMessage: "Missing translation",
          })}
        </NavButton>
        <NavButton
          isScrolled={isScrolled}
          name={"contact"}
          onClick={handleClick}
        >
          {intl.formatMessage({
            id: "navigation__contact",
            defaultMessage: "Missing translation",
          })}
        </NavButton>
      </Wrapper>
      <LanguageDropdown />
      {showScrollToTop && <ScrollToTop onClick={handleScrollToTop} />}
    </>
  );
};

export default Header;
