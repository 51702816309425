import { useState } from 'react';
import { config } from '../config';
import { LanguageCode } from '../providers/Translations';
export const LOCAL_STORAGE_LANGUAGE_KEY = 'language';

const useAppLanguage = (): [LanguageCode, (languageCode: LanguageCode) => void] => {
  const [appLanguage, setAppLanguage] = useState<LanguageCode>(config.fallbackLanguage);

  const setLanguage = (languageCode: LanguageCode) => {
    localStorage.setItem(LOCAL_STORAGE_LANGUAGE_KEY, languageCode);
    setAppLanguage(languageCode);
  }

  return [appLanguage, setLanguage];
}

export default useAppLanguage;
