import { SyntheticEvent } from "react";
import styled from "styled-components";
import breakpoints from "../../../breakpoints";

const MenuIcon = styled.div<{ open: boolean }>`
  position: fixed;
  top: 2.6rem;
  left: 20px;
  z-index: 999999;
  width: 32px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  box-sizing: border-box;
  padding: 1rem;

  @media only screen and (min-width: ${breakpoints.md}) {
    display: none;
  }

  ${(props) =>
    !props.open
      ? `
      span:nth-child(1) {
        top: 0px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
    }

    span:nth-child(2) {
        top: 8px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
    }

    span:nth-child(3) {
        top: 16px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
    }`
      :
    `{
      span:nth-child(1) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 8px;
        left: 2px;
      }

      span:nth-child(2) {
        width: 0%;
        opacity: 0;
      }

      span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        top: 8px;
        left: 2px;
      }
    }`}
`;

const MenuSpan = styled.span`
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #D4AF37;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
`;

interface BurgerMenuProps {
  open: boolean;
  onClick: (e: SyntheticEvent) => void;
}
const BurgerMenu = ({ open, onClick }: BurgerMenuProps) => {
  return (
    <MenuIcon open={open} onClick={onClick}>
      <MenuSpan />
      <MenuSpan />
      <MenuSpan />
    </MenuIcon>
  );
};

export default BurgerMenu;
