import { FormattedMessage, useIntl } from 'react-intl';
import TriSunca2015 from '../assets/trisunca-traminac-2015.webp';
import TriSunca2016 from '../assets/trisunca-traminac-2016.webp';
import TriSuncaGrasac from '../assets/trisunca-grasac-2019.webp';
import WineListPage from '../components/molecules/WineListPage/WineListPage';
import { useEffect } from 'react';
import TriSuncaLogo from '../assets/trisunca-logo.webp';
import BackgroundImage from '../assets/trisunca-bg.webp';

const TriSunca = () => {
    const intl = useIntl();

    useEffect(() => {
        window.scrollTo(0, 0);
    },[]);

    const wines = [
        {
            id: 1,
            image: TriSunca2015,
            name: <FormattedMessage id='trisunca__traminac_2015_name' defaultMessage="Missing translation value" />,
            description: <FormattedMessage id='trisunca__traminac_2015_description' defaultMessage="Missing translation value" />,
            liter: <FormattedMessage id='trisunca__traminac_2015_liter' defaultMessage="Missing translation value" />,
            urlName: 'trisunca-traminac-2015',
        },
        {
            id: 2,
            image: TriSunca2016,
            name: <FormattedMessage id='trisunca__traminac_2016_name' defaultMessage="Missing translation value" />,
            description: <FormattedMessage id='trisunca__traminac_2016_description' defaultMessage="Missing translation value" />,
            liter: <FormattedMessage id='trisunca__traminac_2016_liter' defaultMessage="Missing translation value" />,
            urlName: 'trisunca-traminac-2016',
        },
        {
            id: 3,
            image: TriSuncaGrasac,
            name: <FormattedMessage id='trisunca__grasac_2019_name' defaultMessage="Missing translation value" />,
            description: <FormattedMessage id='trisunca__grasac_2019_description' defaultMessage="Missing translation value" />,
            liter: <FormattedMessage id='trisunca__grasac_2019_liter' defaultMessage="Missing translation value" />,
            urlName: 'trisunca-grasac-2019',
        }
    ];

    return (
        <WineListPage
            type={'trisunca'}
            backgroundImage={BackgroundImage}
            descriptionText={intl.formatMessage({ id: 'trisunca__description', defaultMessage: 'Missing translation' })}
            wineLogo={TriSuncaLogo}
            wines={wines}
        />
    );
}

export default TriSunca;