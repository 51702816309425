import { MapContainer, Marker, TileLayer, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { LatLngExpression } from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import L from "leaflet";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import styled from "styled-components";
import breakpoints from "../../../breakpoints";

const TileLayerStyled = styled.div`
  margin: 0 5% 5% 5%;

  a {
    svg {
      width: 0;
      height: 0;
    }
  }

  @media only screen and (min-width: ${breakpoints.xxl}) {
    margin: 0 5% 3rem 5%;
  }
`;

const MapWithLocation = () => {
  const position: LatLngExpression = [45.214225955657454, 19.61628547099469];

  const DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
  });

  L.Marker.prototype.options.icon = DefaultIcon;

  return (
    <TileLayerStyled>
      <MapContainer
        style={{ height: "50vh" }}
        zoom={17}
        center={position}
        scrollWheelZoom={false}
        fadeAnimation={true}
        markerZoomAnimation={true}
        tap={false}
        dragging={false}
      >
        <TileLayer
          attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
          url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={position}>
          <Popup>
            Vinarija <br />
            Fruškogorski vinogradi <br />
            <a
              href="https://maps.google.com/?q=Fruskogorski+Vinogradi%2C+Dunavska+5%2C+Banoštor+21312"
              target="_blank"
              rel="noreferrer"
            >
              Find on map
            </a>
          </Popup>
        </Marker>
      </MapContainer>
    </TileLayerStyled>
  );
};

export default MapWithLocation;
