import { useEffect, useState } from 'react';
import MapView from '../assets/fruvin-map.jpeg';
import styled from 'styled-components';
import ImageSkeleton from '../components/atoms/Skeletons/ImageSkeleton/ImageSkeleton';

const Wrapper = styled.div`
    height: 100vw;
`;

const CoverImageStyled = styled.img<{loaded: boolean}>`
    width: 100%;
    height: 100%;

    display: ${(props) => props.loaded ? `block` : `none`};
`;

//@todo: interaktivna?
const Map = () => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    },[]);

    const handleLoad = () => {
        setLoaded(true);
    }

    return (
        <Wrapper>
        <picture onLoad={handleLoad}>
          <source srcSet={`${MapView}`} media="(max-width: 767px)" />
          <source srcSet={`${MapView}`} media="(min-width: 768px)" />
          <CoverImageStyled
            src={`${MapView}`}
            alt={"Map of the vineyard"}
            loaded={loaded}
          />
        </picture>
        <ImageSkeleton loaded={loaded} />
      </Wrapper>
    );
}

export default Map;