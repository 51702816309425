import React from 'react';
import AppGrasac from '../assets/app-first.webp';
import AppFirst from '../assets/app-first.jpg';
import AppSecond from '../assets/app-second.jpg';
import AppThird from '../assets/app-third.jpg';
import Product from '../assets/app-temp-cover.webp';
import AboutCover from '../assets/app-about.jpg';
import styled from 'styled-components';

const posts = [
    { imageUrl: AppGrasac },
    { imageUrl: AppFirst },
    { imageUrl: AppSecond },
    { imageUrl: AppThird },
    { imageUrl: Product},
    { imageUrl: AboutCover}
];

const PostPreviewGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1px;
`;

const PostPreviewItem = styled.div`
    position: relative;
    overflow: hidden;
    border-radius: 8px;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.2s;
    }
`;

const AppImages = () => {
    return (
        <PostPreviewGrid>
          {posts.map((post, index) => (
            <PostPreviewItem key={index}>
              <img src={post.imageUrl} alt={`Post ${index}`} />
            </PostPreviewItem>
          ))}
        </PostPreviewGrid>
      );
}

export default AppImages;