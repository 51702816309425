import React from 'react';
import styled from 'styled-components';
import VideoMain from '../../../assets/fruvin-video.mp4';
import Main from '../../../assets/fruvin-main.jpeg';

const VideoWrapper = styled.video`
    width: 100%;
`;

const MainVideo = () => {
    return (
        <>
            <VideoWrapper id='map-video' autoPlay={true} loop={true} muted={true} playsInline={true} poster={Main}>
                <source src={VideoMain} type="video/mp4" />
                Sorry, your browser doesn't support videos.
            </VideoWrapper>
        </>
    );
}

export default MainVideo;