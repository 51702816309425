import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as  ArrowRight } from '../../../assets/arrow-right.svg';
import breakpoints from "../../../breakpoints";
import { useState } from "react";
import ImageSkeleton from "../Skeletons/ImageSkeleton/ImageSkeleton";
import { useIntl } from "react-intl";


const TextCentered = styled.div<{textColor?: string}>`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${props => (props.textColor)};
    font-size: 20px;
    font-weight: bold;

    @media only screen and (min-width: ${breakpoints.md}) {
        font-size: 42px;
    }

    svg {
        width: 50px;
        cursor: pointer;
    }
`;

const FlexBox = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    white-space: pre-wrap;
`;

const Wrapper = styled.div`
    width: 100%;
    aspect-ratio: 2 / 1;
`;

const CoverImageStyled = styled.img<{loaded: boolean}>`
    width: 100%;

    display: ${(props) => props.loaded ? `block` : `none`};

    @media only screen and (min-width: ${breakpoints.md}) {
        aspect-ratio: 2 / 1;
    }
`;

type ImageProps = {
    image: string;
    text?: string;
    navigateTo?: string;
    textColor?: string;
    loading?: "lazy";
}
const Image = ({image, text, navigateTo, textColor, loading}: ImageProps) => {
    const navigate = useNavigate();
    const intl = useIntl();
    const [loaded, setLoaded] = useState(false);

    const handleLoad = () => {
        setLoaded(true);
    }

    //TODO: relative pomeri
    return (
        <div style={{position: 'relative'}}>
            <Wrapper>
                <picture onLoad={handleLoad}>
                    <source
                        srcSet={image}
                        media='(min-width: 768px)'
                    />
                    <source
                        srcSet={image}
                        media='(max-width: 767px)'
                    />
                    <CoverImageStyled
                        src={image}
                        alt={navigateTo}
                        loaded={loaded}
                    />
                </picture>
                <ImageSkeleton loaded={loaded} />
            </Wrapper>
            {(text && navigateTo && loaded) &&
                <TextCentered textColor={textColor}>
                    {intl.formatMessage({ id: 'home__otkrijte', defaultMessage: 'Missing translation' })}
                    <br/>
                    <FlexBox>
                        {text}
                        <ArrowRight width={32} height={32} onClick={() => navigate(navigateTo)}/>
                    </FlexBox>
                </TextCentered>
            }
        </div>
    );
}

export default Image;