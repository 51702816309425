import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { config } from '../../config';
import useAppLanguage from '../../hooks/useAppLanguage';
import srMessages from '../../i18n/sr.json';

const languages = ['sr', 'en', 'ru', 'zh-CN'] as const;
type ElementType<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<
  infer ElementType
>
  ? ElementType
  : never;
export type LanguageCode = ElementType<typeof languages>;

type LanguageContext = {
  currentLanguage: LanguageCode;
  setLanguage: (language: LanguageCode) => void;
  languages: Readonly<string[]>;
};
export const TranslatedTheme = React.createContext<LanguageContext>({
  currentLanguage: config.fallbackLanguage,
  setLanguage: () => { },
  languages,
});

type ComponentProps = {
  children: ReactNode;
};
export const TranslatedComponent =
  ({ children }: ComponentProps): JSX.Element | null => {
    const [messages, setMessages] = useState<any>(srMessages);
    const [language, setLanguage] = useAppLanguage();

    const languageContext: LanguageContext = {
      currentLanguage: language,
      languages,
      setLanguage
    };

    const loadTranslations = useCallback(async () => {
      const translations = await import(`../../i18n/${language}.json`);
      setMessages(translations);
    }, [language]);

    useEffect(() => {
      loadTranslations();
    }, [loadTranslations]);

    if (!messages) return null;

    return (
      <IntlProvider messages={messages} locale={language} defaultLocale={config.fallbackLanguage}>
        <TranslatedTheme.Provider value={languageContext}>
          {children}
        </TranslatedTheme.Provider>
      </IntlProvider>
    );
}

type Props = {
  children: ReactNode;
};
export const TranslationsProvider = ({ children }: Props): JSX.Element => {
  return (
    <TranslatedComponent>
      {children}
    </TranslatedComponent>
  );
}
