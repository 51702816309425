import { LOCAL_STORAGE_LANGUAGE_KEY } from "./hooks/useAppLanguage";
import { LanguageCode } from "./providers/Translations";

let fallbackLanguage;

const localStorageLanguage = localStorage.getItem(LOCAL_STORAGE_LANGUAGE_KEY);

if(localStorageLanguage) {
    fallbackLanguage = localStorageLanguage as LanguageCode;
}

export const config = {
    fallbackLanguage: fallbackLanguage || 'sr' as LanguageCode
};
