const TermsAndConditions = () => {
    return (
        <div style={{padding: '3rem 5%', letterSpacing: '0', fontSize: '12px'}}>
            <h1 style={{textAlign: 'center'}}>POLITIKA PRIVATNOSTI</h1> <br/>

            Dobrodošli na aplikaciju FruvinReservation. Privredno društvo Fruškogorski vinogradi d.o.o. Banoštor je sačinilo ovu Politiku privatnosti koja se primenjuje na prikupljanje i obradu Vaših podataka o ličnosti putem naše aplikacije FruvinReservation. <br/><br/>

            Posvećeni smo zaštiti Vaših podataka o ličnosti, budući da Vašu privatnost shvatamo veoma ozbiljno i preduzimamo sve neohodne korake kako bismo je u svakom trenutku zaštitili. Stoga Vas molimo da pažljivo pročitate ovu Politiku privatnosti,
            kako biste se upoznali sa načinom i obimom obrade, koje podatke o ličnosti obrađujemo, kako koristimo Vaše podatke i koja prava Vam stoje na raspolaganju u vezi sa obradom podataka o ličnosti. <br/><br/>

            Ukoliko se ne slažete sa našom Politikom privatnosti, molimo Vas da odmah prestanete sa korišćenjem naše mobilne aplikacije, te nas kontaktirate putem e-maila info.aplikacija@fruvin.com, kako bismo Vas izbrisali iz baze korisnika naše aplikacije u najkraćem roku. <br/><br/>
            Ukoliko imate bilo kakva pitanja, molimo Vas da nas kontaktirate slanjem e-maila na info.aplikacija@fruvin.com. <br/><br/>


            <h2>1. SKRAĆENICE I DEFINICIJE</h2>
            “ZZPL” znači Zakon o zaštiti podataka o ličnosti („Službeni glasnik RS”, broj 87/2018); <br/><br/>
            “Poverenik” znači Poverenik za informacije od javnog značaja i zaštitu podataka o ličnosti Republike Srbije; <br/><br/>
            “Društvo”, “Fruškogorski vinogradi”, “Mi”, „Nama“ se odnosi na “Fruškogorski vinogradi” d.o.o. Banoštor; <br/><br/>
            „Korisnik“, „Vi“, „Vama“, „Vaše“ se odnosi na Vas, korisnika naše FruvinReservation aplikacije; <br/><br/>
            „Aplikacija„ označava našu FruvinReservation aplikaciju; <br/><br/>
            „Politika“ označava ovu Politiku privatnosti; <br/><br/>
            Pojmovi upotrebljeni u ovoj Politici imaju sledeće značenje: <br/><br/>
            “Podatak o ličnosti” je svaki podatak koji se odnosi na fizičko lice čiji je identitet određen ili odrediv, neposredno ili posredno, posebno na osnovu oznake identiteta, kao što je ime i identifikacioni broj, podataka o lokaciji, identifikatora u elektronskim komunikacionim mrežama ili jednog,
            odnosno više obeležja njegovog fizičkog, fiziološkog, genetskog, mentalnog, ekonomskog, kulturnog i društvenog identiteta; <br/><br/>
            “Posebne vrste podataka o ličnosti” su podaci kojim se otkriva rasno ili etničko poreklo, političko mišljenje, versko ili filozofsko uverenje ili članstvo u sindikatu, genetski podataci, biometrijski podaci,
            podaci o zdravstvenom stanju, seksualnom životu ili seksualnoj orijentaciji fizičkog lica; <br/><br/>
            “Obrada podataka o ličnosti” je svaka radnja ili skup radnji koje se vrše automatizovano ili neautomatizovano sa podacima o ličnosti ili njihovim skupovima, kao što su prikupljanje, beleženje, razvrstavanje, grupisanje, odnosno strukturisanje, pohranjivanje, upodobljavanje ili menjanje, otkrivanje, uvid, upotreba, otkrivanje prenosom, odnosno dostavljanjem, umnožavanje, širenje ili na drugi način činjenje dostupnim, upoređivanje, ograničavanje, brisanje ili uništavanje (u daljem tekstu: obrada);
            “Rukovalac” je Društvo kao pravno lice koje određuje svrhu i način obrade podataka o ličnosti; <br/><br/>
            “Obrađivač” je svako fizičko ili pravno lice, koji obrađuje podatke o ličnosti u ime rukovaoca. <br/><br/>


            <h2>2. RUKOVALAC</h2>
            Rukovalac je „Fruškogorski vinogradi društva sa ograničenom odgovornošću za proizvodnju, trgovinu i usluge Banoštor, adresa Dunavska 5, Banoštor, opština Beočin, delatnost 1102 – proizvodnja vina od grožđa, mb: 20198133, PIB: 104627220, web: fruvin.com, kontakt telefon: +381 (0) 64/889-0023 i +381 (0) 21/6613-500, email: info@fruvin.com. <br/><br/>
            Rukovalac određuje svrhu, osnov i načine obrade Vaših podataka o ličnosti i rukovalac je odgovoran za Vaše podatke o ličnosti. <br/><br/>

            <h2>3. VRSTA PODATAKA O LIČNOSTI KOJI SE OBRAĐUJU</h2>

            a) Podatke o ličnosti svih korisnika koji uspešno izvrše rezervacije putem FruvinReservation aplikacije; <br/><br/>
            b) Podatke o ličnosti korisnika koji oduče da kreiraju korisnički nalog; <br/><br/>
            c) Podatke o ličnosti koje korisnik odluči da dostavi kontaktiranjem Fruškogorskih vinograda;<br/><br/>
            d) Podatke o ličnosti koje automatski prikupljamo; <br/><br/>
            e) Podatke o ličnosti koji su neophodni radi rešavanja reklamacija i u slučaju pokretanja sudskih, upravnih i ostalih postupaka; <br/><br/>
            f) Podatke o ličnosti koji su neophodni radi realizacije ugovora o saradnji; <br/><br/>
            g) Podatke o ličnosti koje obrađujemo u svrhe direktnog oglašavanja (marketinga). <br/><br/>


            A) Prilikom realizacije Vaše rezervacije preko naše FruvinReservation aplikacije, obrađujemo sledeće podatke o svakom korisniku radi potvrde i realizacije rezervacije putem aplikacije, bez obzira na to da li rezervacije vrše kao gosti ili preko svog korisničkog naloga: <br/>
            - Ime i prezime; <br/>
            - Broj telefona; <br/><br/>
            Prilikom popunjavanja forme, imate opciju da nam svojevoljno upišete i ime kompanije. <br/><br/>
            Prilikom vršenja online plaćanja, mi ne obrađujemo niti čuvamo podatke o Vašoj platnoj kartici. Prilikom plaćanja, vrši se automatska redirekcija na internet stranicu banke gde se i realizuje plaćanje. Naime, prilikom unošenja podataka o platnoj kartici, poverljive informacije se prenose putem javne mreže u zaštićenoj (kriptovanoj) formi na internet stranicu banke. Sigurnost podataka prilikom kupovine, garantuje i procecor platnih kartica, „Banca Intesa“ a.d. Beograd, pa se tako kompletni proces naplate obavlja na stranicama banke. Prilikom plaćanja platnom karticom nakon što korisnik potvrdi rezervacije biće usmeren na stranicu Banca Intesa na kojoj vrši unos podataka sa platne kartice i obavlja plaćanje. Podaci o platnoj kartici nijednog trenutka nisu dostupni zaposlenima kod Poslodavca. <br/><br/>

            B) Naši korisnici imaju mogućnost da kreiraju korisnički nalog, kako bi olakšali svako sledeće korišćenje aplikacije. U tom slučaju, pored podataka koje obrađujemo od svakog korisnika (ime i prezime, broj telefona), obrađujemo i podatke unete prilikom kreiranja korisničkog naloga korisnika:<br/>
            - E-mail adresa;<br/>
            - Lozinka<br/><br/>

            C) Ukoliko nas direktno kontaktirate putem e-maila, telefona ili slanjem pisma na našu adresu, moguće je da nam dostavite i dodatne informacije o Vama. <br/><br/>

            D) Pristupanjem i korišćenjem naše aplikacije, možemo automatski da prikupljamo i obrađujemo neke od Vaših podataka o ličnosti, poput IP adrese, tipa i modela uređaja, jedinstvenog ID broja uređaja, tipu internet pretraživača, lokacije (region, grad) ukoliko na Vašem uređaju to dozvolite, i druge tehničke informacije. Ove informacije ne otkrivaju Vaš identitet i prvenstveno su neophodne za održavanje sigurnosti i funkcionisanja naše aplikacije, za naše interne svrhe. <br/><br/>

            E) Za svrhe rešavanja zahteva po osnovu reklamacija ili odustanka od rezervacije, kao i eventualnih sudskih, upravnih ili drugih postupaka, potrebni su nam Vaši podaci o imenu i prezimenu, adresi prebivališta, broj telefona, e-mail adresa, pol, datum rođenja, jedinstveni matični broj građana (prilikom vršenja uplata na Vaš račun ili povraćaja novca kod reklamacije ili odustanka od kupovine). <br/><br/>

            F) Možemo da prikupljamo podatke o poslovnim e-mail adresama, brojevima telefona, odnosno imenu i prezimenu li koje je kod kompanije-klijenta ovlašćeno za komunikaciju sa nama, radi realizacije poslovne saradnje ili ispunjenja važećeg ugovora. <br/><br/>

            G) Podatke o ličnosti – ime i prezime, e-mail adresa možemo da obrađujemo i u svrhe ostvarivanja direktnog oglašavanja (marketinga) i slanja newlettera kako bismo sve klijente obavestili o aktuelnostima, trenutnim promocijama i ponudama, a sve u cilju ostvarivanja legitimnih interesa u vezi sa poslovanjem Društva. <br/><br/>

            <h2>4. OBIM OBRADE</h2>
            Fruškogorski vinogradi ne obrađuju veći broj ili drugu vrstu podataka o ličnosti od onih koji su neophodni radi ispunjenja svrhe iz koje se obrađuju. <br/><br/>

            <h2>5. IZVORI IZ KOJIH SE PRIKUPLJAJU PODACI O LIČNOSTI</h2>
            Vaše podatke o ličnosti prikupljamo direktno od Vas, i to putem ispunjavam forme koja se nalazi na našoj aplikaciji ili pristupom preko Vašeg Google naloga. Osim toga, Vi možete direktno da nam pružite dodatne podatke, ukoliko nam prilikom kontaktiranja dostavite dodatne podatke o Vama, u slučaju procedure povraćaja novca usled prihvaćene reklamacije i odustanka od rezervacije, eventualnog spora, ili iz ugovora – ukoliko se radi o realizaciji ugovora o saradnji sa poslovnim partnerima.

            <h2>6. SVRHA I OSNOV OBRADE</h2>
            Podatke o ličnosti opisane u tački 3 ove Politike obrađujemo: a) na osnovu Vaše saglasnosti – pristanka da izvršite rezervaciju putem naše aplikacije; b) radi ispunjenja ugovornih obaveza po osnovu ugovora o saradnji koji sa Vama zaključujemo (kupovina preko interneta se smatra zaključenjem ugovora o kupovini) i c) izvršavanje zakonskih obaveza (prvenstveno obaveza koje imamo prema Zakonu o zaštiti potrošača); d) na osnovu Vašeg pristanka u slučaju da se prijavite na naš newsletter; e) radi ostvarivanja legitimnog interesa u vezi sa poslovanjem kod direktnog oglašavanja (marketinga). <br/><br/>
            Obradu vaših podataka ćemo vršiti pre svega u svrhe: <br/><br/>
            - postupanja prema Vašem zahtevu u vezi sa vršenjem rezervacije, radi izvršenja ugovora o saradnji koji sa Vama zaključujemo, i ispunjenja naših zakonskih obaveza kao prodavca; <br/><br/>
            - može se ukazati potreba da obrađujemo vaše lične podatke da bismo postupili u skladu sa zakonom ili sudskim nalogom, odnosno nalogom drugog državnog organa (npr.dostavljanje video zapisa na zahtev policije ili suda); <br/><br/>
            - budući da primenjujemo osiguranje za brojne rizike koji se mogu dogoditi u našoj delatnosti, kao što su osiguranje od odgovornosti za štetu prouzrokovanu trećim licima, osiguranje od profesionalne odgovornosti i sl, moguće je da budemo primorani da vršimo obradu vaših ličnih podataka, npr. radi obrade konkretnog odštetnog zahteva;<br/><br/>
            - može se dogoditi da vršimo obradu vaših ličnih podataka za potrebe rešavanja sporova, žalbi ili zakonskih postupaka, ili ako sumnjamo da je došlo do nepravilnosti koje zahtevaju dodatno ispitivanje. Navedene aktivnosti preduzimamo imajući u vidu da za to imamo zakonski opravdan interes, ili da bismo ispunili zakonske obaveze; <br/><br/>
            - ostvarivanja direktnog oglašavanja (marketinga) i slanja newlettera kako bismo sve klijente obavestili o aktuelnostima, trenutnim promocijama i ponudama. <br/><br/>

            <h2>7. ŠTA NEĆEMO RADITI</h2>
            Nećemo ni na koji način prodati bilo koji Vaš podatak o ličnosti, objaviti i učiniti dostupnim Vaše podatke trećim licima (osim u slučaju kada je drugačije predviđeno zakonom), niti vršiti obradu Vaših podataka o ličnosti na način koji nije predviđen ovom Politikom. <br/><br/>

            <h2>8. NAČIN NA KOJI SE INFORMACIJE ČUVAJU I SKLADIŠTE</h2>
            Čuvamo Vaše podatke o ličnosti automatizovano, na softveru. Budući da smo posvećeni zaštiti i bezbednosti Vaše privatnosti, primenjujemo najsavremenije tehničke, organizacione i kadrovske mere kako bismo Vaše podatke o ličnosti zaštitili od gubitaka, uništenja, nedopuštenog pristupa, promene, objavljivanja i svakog drugog oblika nezakonite obrade. Stoga, primenjujemo, između ostalog mere, pristup ličnim podacima sa lozinkom, meru ograničenog pristupa osetljivim podacima, enkriptovani transfer osetljivih podataka poslatih od strane korisnika popunjavanjem forme za prijavu i vršenje rezervacije. Sve stranice naše aplikacije na kojima se vrši razmena poverljivih podataka (lozinka, plaćanje putem interneta i sl) zaštićene su upotrebom https sigurnosnog protokola, koji je standardna tehnologija zaštite podataka koja omogućava sigurnu razmenu podataka između Vašeg pretraživača i stranica naše aplikacije. U skladu sa mogućnostima i tehničkim razvojem, nastavićemo da poboljšavamo tehničke, organizacione i kadrovske mere, kako bi zaštita Vaših podataka uvek bila osigurana do najveće moguće mere. <br/><br/>
            Osim toga, obezbedili smo da Vaši lični podaci budu zaštićeni od neovlašćenog pristupa i izmena. Samo određena lica koja su za to posebna ovlašćena, mogu pristupati i obrađivati Vaše podatke o ličnosti. Sva ovlašćena lica za pristup i obradu podataka o ličnosti u okviru Fruškogorskih vinograda su dužni da čuvaju poverljivost podataka i odgovorni su za poštovanje zaštite privatnosti. Svako kršenje ili ukoliko sumnjamo da je došlo do kršenja bezbednosti podataka, prijavićemo u skladu sa zakonom. <br/><br/>
            Prihvatanjem Politike saglasni ste da Fruškogorski vinogradi ne mogu sa sigurnošću da garantuju apsolutnu sigurnost, bezbednosni integritet i poverljivost podataka o ličnosti, te da nećemo snositi odgovornost za gubitak/uništenje/otkrivanje ukoliko smo prethodno preduzeli sve mere koje smo imali na raspolaganju u tom trenutku. <br/><br/>
            Imajući navedeno u vidu, prilikom registracije korisničkog naloga na aplikaciji, korisnicima savetujemo da svoju lozinku ne otkrivaju drugim osobama, jer su korisnici odgovorni za sve radnje izvršene preko korisničkog naloga. Ukoliko Vam je poznato ili sumnjate da je nekom poznata Vaša lozinka, možete samostalno da resetujete lozinku i uputstva za promenu će vam stići na registrovanu e-mail adresu. Ukoliko postoji bilo kakva sumnja da postoji propust u sigurnosti, imamo pravo da od Vas tražimo da promenite lozinku ili  da vam privremeno blokiramo korisnički nalog. <br/><br/>

            <h2>9. KO IMA PRISTUP VAŠIM PODACIMA</h2>
            Međutim, u određenim slučajevima, a o čemu bismo Vas blagovremeno obavestili, Vašim podacima o ličnosti mogu da imaju pristup: <br/><br/>
            - Treća lica koja deluju u naše ime kao obrađivači. U ovim slučajevima, treća lica mogu da koriste vaše lične podatke isključivo za svrhe koje su gore navedene i isključivo u skladu sa našim instrukcijama, npr. osiguravajuće kompanije sa kojim imamo ugovor. U ovoj situaciji se obrađivaču ustupaju samo podaci neophodni za ostvarenje svrhe ugovorene obrade, i obrađivači ih ne mogu koristiti za druge svrhe. U ovim slučajevima, uslovi obrade podataka i odgovornost za zaštitu podataka definisaće se ugovorom između Društva i obrađivača. <br/><br/>
            - Saradnici u sektorima koji se bave gore navedenim poslovima mogu imati pristup Vašim podacima o ličnosti, ali samo kada je to strogo neophodno da bi obavili svoje radne zadatke. U tom slučaju, pristup će se odobriti isključivo kada je to neophodno za gore navedene svrhe i samo ako je saradnih obavezan da čuva poverljivost informacija (odredbom o poverljivosti ili ugovorom o poverljivosti)); <br/><br/>
            - Treća lica koja su odgovorna za čuvanje Vaših podataka o ličnosti, nezavisno od Društva (računovođe, advokati i sl). U tom slučaju, Vaši podaci o ličnosti će biti zaštićeni u skladu sa politikom zaštite podataka tog lica; <br/><br/>
            - Ukoliko budemo morali da ustupimo podatke državnim organima u slučajevima kada je zakonom propisana takva obaveza; <br/><br/>

            U navedenim slučajevima, Poslodavac će ustupiti samo one podatke koji su neophodni za ostvarenje svrhe iz kojih se prenose trećim licima.
            Vaši podaci o ličnosti u Srbiji, a skladištimo ih u bazama podataka koje se nalaze na serverima Digital Ocean Droplet koji se nalaze u inostranstvu. Na podatke o ličnosti koji se iznose u inostranstvo primenjivaće se pravila koja važe za iznošenje podataka. <br/><br/>

            <h2>10. KOLIKO DUGO ČUVAMO VAŠE PODATKE O LIČNOSTI?</h2>
            Vaše podatke o ličnosti čuvamo u periodu u kome su neophodni za ostvarenje svrhe za koju se obrađuju. Nakon ispunjenja svrhe, mi trajno brišemo Vaše podatke o ličnosti, ili onemogućavamo pristup-postaju anonimni, osim ukoliko se ne radi o podacima na čije čuvanje smo obavezani zakonom vremenski neograničeno. Kriterijumi za određivanje vremena tokom koga čuvamo ove podatke su: <br/><br/>
            - Prema zakonskim obavezama koji se na nas primenjuju; <br/><br/>
            - Prema potrebi, za uspostavljanje, vršenje ili odbranu pravnog zahteva, u skladu sa važećim zakonima; <br/><br/>
            - Dok se rezervacija ne realizuje; <br/><br/>
            - Ako imate otvoren nalog, dok god taj nalog postoji, odnosno dok ne bude obrisan na Vaš zahtev). <br/><br/>
            U slučaju da se osnov obrade zasniva na Vašoj saglasnosti za obradu, a Vi povučete svoj pristanak na obradu, mi ćemo trajno obrisati te podatke o ličnosti, osim ukoliko nas zakon ne obavezuje da ih čuvamo. <br/><br/>

            <h2>11. KOJA SU VAŠA PRAVA?</h2>
            U pogledu zaštite podataka o ličnosti, uživate sledeća prava: <br/><br/>

            Pravo na obaveštavanje/informisanje o obradi: u trenutku prikupljanja podataka o ličnosti, Poslodavac je dužan da obavesti lice čiji se podaci prikupljaju o identitetu i kontakt podacima Poslodavca i/ili njegovog predstavnika,
            vrsti podataka koji se prikupljaju, o svrsi nameravane obrade i pravnom osnovu za obradu, o primaocu podataka o ličnosti, o činjenici da li Poslodavac namerava da iznese podatke o ličnosti u drugu državu ili međunarodnu organizaciju,
            o roku čuvanja podataka, ili ako to nije moguće o kriterijumima za određivanje tog roka pravima lica (da zahteva pristup, ispravku ili brisanje svojih podataka o ličnosti, pravu na ograničenost obrade, prava na prigovor i prava na prenosivost podataka,
             pravu na opoziv pristanka u bilo koje vreme, pravo prigovora i pritužbe Povereniku), o tome da li je davanje podataka zakonska, ugovorna obaveza Poslodavca ili neophodan uslov za zaključenje ugovora i drugo Zakonom propisano; <br/><br/>

            Pravo na pristup: Lice na koje se podaci odnose ima pravo na informaciju da li Poslodavac obrađuje njegove podatke, pravo na uvid i pristup svojim podacima o ličnosti, a što uključuje pravo na pregled, čitanje, slušanje podataka i
            pravljenje zabeležbi. Osim toga, ima pravo na pristup informacijama o svrsi obrade, vrstama podataka o ličnosti koji se obrađuju, o primaocu ili vrstama primalaca kojima su podaci o ličnosti otkriveni ili će im biti otkriveni posebno u
            drugim državama ili međunarodnim organizacijama, predviđenom roku čuvanja podataka o ličnosti, ili ako to nije moguće o kriterijumima za određivanje tog roka, o njegovom pravu da zahteva ispravku ili brisanje svojih podataka o ličnosti,
            pravu na ograničenje obrade i prava na prigovor na obradu, pravo da se podnese pritužba Povereniku, dostupne informacije o izvoru podataka o ličnosti, ako nisu prikupljeni direktno od tog lica, i drugim slučajevima ako je predviđeno Zakonom;<br/><br/>

            Pravo na dobijanje kopije: Lice na koje se podaci odnose ima pravo da od Poslodavca zahteva da izdavanje kopije podataka koje obrađuje, u pisanom ili elektronskom obliku. <br/><br/>

            Pravo na ispravku i dopunu: Lice na koje se podaci o ličnosti odnose ima pravo da, u slučaju netačnih podataka, zahteva da se netačni podaci isprave. Poslodavac je dužan da netačne podatke ispravi bez nepotrebnog odlaganja. Ukoliko je neophodno
            radi ispunjenja svrhe obrade, lice na koje se podaci o ličnosti odnose, može da svoje nepotpune podatke o ličnosti dopuni, uključujući i davanje dodatnih izjava. U slučaju promene nekih podataka o ličnosti, lice na koje se podaci o ličnosti odnose
            ima pravo na ažuriranje podataka, što će Poslodavac evidentirati bez nepotrebnog odlaganja. <br/><br/>

            Pravo na brisanje podataka o ličnosti: Lice na koje se podaci o ličnosti odnose ima pravo da zahteva brisanje njegovih podataka o ličnosti, kao i na prekid i privremenu obustavu obrade; <br/><br/>

            Pravo na ograničenje obrade - Lice na koje se podaci odnose ima pravo da zahteva da se obrada njegovih podataka o ličnosti ograniči ukoliko 1) osporava tačnost podataka o ličnosti (o roku u kome je omogućeno Poslodavcu-rukovaocu da
            proveri tačnost podataka o ličnosti), 2) ukoliko smatra da je obrada nezakonita (a ne zahteva brisanje podataka), 3) ukoliko Poslodavcu-rukovaocu nisu više potrebni podaci o ličnosti za ostvarivanje srvhe obrade, ali ih je lice na koje se podaci o
            ličnosti odnose zatražilo u cilju podnošenja, ostvarivanja ili odbrane pravnog zahteva, 4) ukoliko je to lice podnelo prigovor na obradu (u toku procenjivanja da li pravni osnov za obradu preteže nad interesima tog lica). U slučaju da je obrada
            ograničena, podaci o ličnosti čija je obrada ograničena mogu se dalje obrađivati jedino na osnovu pristanka lica na koje se ti podaci o ličnosti odnose, osim u slučajevima predviđenim relevantnim propisima. U slučaju ograničenja obrade,
            Poslodavac je dužan da informiše lice na koje se podaci o ličnosti odnose o prestanku ograničenja, pre isteka roka na koje je ograničenje važilo.<br/><br/>

            Pravo na prenosivost podataka o ličnosti – Lice na koje se podaci o ličnosti odnose ima pravo da zahteva od Poslodavca prenošenje ličnih podataka drugom rukovaocu, kada je to tehnički izvodljivo, odnosno kada su podaci o ličnosti,
            koji su predmet zahteva za prenošenje, strukturirani, i u uobičajeno korišćenom i elektronski čitljivom formatu, ukoliko je to lice dalo pristanak na obradu tih podataka i ukoliko se obrada vrši automatizovano. <br/><br/>

            Pravo na povlačenje saglasnosti – Lice na koje se podaci o ličnosti odnose ima pravo da u bilo kom trenutku povuče saglasnost za obradu podataka o ličnosti u pisanoj formi, ukoliko je osnov za obradu tih podataka o ličnosti zasnovan na pristanku/saglasnosti
            lica na koja se podaci o ličnosti odnose; <br/><br/>

            Pravo na prigovor i automatizovano donošenje odluka – Lice na koje se podaci o ličnosti odnose ima pravo da u svakom trenutku podnese prigovor Poslodavcu, ako smatra da je to opravdano u odnosu na posebnu situaciju u kojoj se nalazi,
            kao i da se na to lice ne primenjuje odluka doneta isključivo na osnovu automatizovane obrade, uključujući i profilisanje, ako se tom odlukom proizvode pravne posledice po to lice, ili ta odluka značajno utiče na njegov položaj.
            Poslodavac će biti dužan da prekine sa takvom obradom podataka o ličnosti, osim ukoliko je predočio tom licu da postoje zakonski razlozi za obradu koji pretežu nad interesima, pravima ili slobodama lica na koja se podaci odnose ili su u
            vezi sa podnošenjem, ostvarivanjem ili odbranom pravnog zahteva.<br/><br/>

            Osim toga, možete da se protivite obradi podataka o ličnosti u svrhe direktnog oglašavanja (marketinga) i profilisanja. <br/><br/>
            U slučaju da niste zadovoljni našim odgovorom na zahtev za ispunjavanje prava u pogledu zaštite ličnih podataka, imate pravo da podnese žalbu Povereniku za informacije od javnog značaja i zaštitu podataka o ličnosti (https://www.poverenik.rs/sr/). <br/><br/>

            <h2>12. PROMENE POLITIKE O ZAŠTITI PODATAKA O LIČNOSTI</h2>
            Kako bismo u svakom trenutku brinuli o Vašoj privatnosti i unapredili svoje mere radi zaštite Vaše privatnosti, te uskladili poslovanje za zakonskim odredbama, imamo pravo da u svakom trenutku vršimo izmene i dopune ove Politike. Sve izmene i dopune ove Politike biće objavljene na našoj aplikaciji i moćićete da im pristupite u svakom trenutku. <br/><br/>

            <h2>13. KONTAKTIRAJTE NAS</h2>
            Ukoliko imate neka pitanja, komentare ili primedbe u vezi sa obradom Vaših podataka o ličnosti, molimo Vas da nas kontaktirate putem e-maila info.aplikacija@fruvin.com, broj telefona +381 (0) 64/889-0023 i +381 (0) 21/6613-500.
            Na Vaš zahtev odgovorićemo najkasnije u roku od 30 dana od dana prijema zahteva. U slučaju složenosti ili velikog broja zahteva, moguće je da će nam biti potreban dodatan rok za odgovor na zahtev. Taj rok ne može biti duži od dodatnih 60 dana i
            o tome ćemo vas posebno obavestiti. Ukoliko je Vaš zahtev očigledno neosnovan ili se učestalo ponavlja, možemo da ga odbijemo ili da naplatimo troškove za njegovo ostvarivanje. Smatra se da je učestalo ponavljanje kada nam se obratite sa
            zahtevom za ostvarenje nekog od prava pet, ili više puta, odgovorićemo na Vaš zahtev samo ukoliko za to imate opravdan razlog. <br/><br/><br/>

            <h1 style={{textAlign: 'center'}}>USLOVI KORIŠĆENJA</h1> <br/>

            <h1>STANDARDI I POSEBNE ODREDBE KOJE SE ODNOSE NA E-COMMERCE</h1>

            <h2>1) OSNOVNI PODACI O FIRMI</h2>
            „Fruškogorski vinogradi“ društvo sa ograničenom odgovornošću za proizvodnju, trgovinu i usluge Banoštor, adresa Dunavska 5, Banoštor, opština Beočin, delatnost 1102 – proizvodnja vina od grožđa, mb: 20198133,
            PIB: 104627220, web:www.fruvin.com, kontakt telefon: +381 (0) 64/889-0023 i +381 (0) 21/6613-500, email: info@fruvin.com. <br/><br/>

            <h2>2) IZJAVA O KONVERZIJI</h2>
            U slučaju da su na e-commerce prodajnom mestu cene izražene i u stranoj valuti (evro, američki dolar itd), Rukovalac je u obavezi da predoči sledeću izjavu o konverzaciji: <br/><br/>
            „All payments will be effected in Serbian currency – dinar (RSD). The amount your credit card account will be charged or is obtained through the conversion of the price in Euro into Serbian dinar according to the current
            exchange rate of the Serbian National Bank. When charging your credit card, the same amount is converted into your local currency according to the exchange rate of credit card associations. As a result of this conversion
            there is a possibility of a slight difference from the original price stated in our web site.“ <br/><br/>

            „Sva plaćanja biće izvršena u lokalnoj valuti Republike Srbije – dinar (RSD). Za informativni prikaz cena u drugim valutama koristi se srednji kurs Narodne banke Srbije. Iznos za koji će biti zadužena Vaša platna
            kartica biće izražen u Vašoj lokalnoj valuti kroz konverziju u istu po kursu koji koriste kartičarske organizacije, a koji nama u trenutku transakcije ne može biti poznat. Kao rezultat ove konverzije postoji
            mogućnost neznatne razlike od originalne cene navedene na našem sajtu.“ <br/><br/>

            <h2>3) KONTAKT PODACI – KORISNIČKI SERVIS </h2>
            Ukoliko imate pitanja, komentare ili primedbe molimo Vas da nas kontaktirate putem e-maila info@fruvin.com, broj telefona +381 (0) 64/889-0023 i +381 (0) 21/6613-500. <br/><br/>

            <h2>4) OPIS I NAČIN PRUŽANJA USLUGE</h2>
            Fruvin aplikacija je namenjena za online rezervisanje termina za degustaciju vina iz asortimana Fruškogorskih vinograda. Prilikom rezervisanja termina za degustaciju vina,
            korisnici aplikacije biraju željeni datum, vreme i vrstu degustacionog paketa, a nakon vršenja online plaćanja vrši se potvrda rezervacije željenog termina za degustaciju vina.
            U ponudi su različiti paketi, koji se razlikuju  po broju i vrstama vina za degustaciju, a koji su isključivo iz asortimana Fruškogorskih vinograda. U slučaju rezervacije za degustaciju vina iz naših standardnih degustacionih paketa,
            Korisnici imaju pravo da otkažu svoju rezervaciju najkasnije do 7 (sedam) dana pre dana za koji je potvrđena rezervacija. U tom slučaju, Fruškogorski vinogradi će u skladu sa tačkom 7 člana 14 izvršiti povraćaj
            100% iznosa koje je korisnik uplatio za odabran paket u najkraćem roku a najduže u roku od 14 (čertnaest) dana od dana otkazivanja rezervacije. Navedeno se ne odnosi na rezervacije događaja, personalizovanih slavlja i/ili
            degustacija van standardnih degistacionih paketa. U slučaju otkazivanja rezervacija izvan standardnih degustacionih paketa u bilo koje vreme, refundacija novca nije moguća. <br/><br/>

            <h2>5) POLITIKA REKLAMACIJA</h2>
            Kupac ima pravo da izvrši reklamaciju, usmenim ili pisanim putem,  direktno u prostorijama Fruškogorskih vinograda, pre, u toku ili odmah nakon izvršenja odabrane usluge. Naknadne reklamacije neće biti uvažene.
            Reklamacije su dozvoljene u slučaju da (i) korisniku nije isporučen odabrani paket u celosti ili delimično (ii) ukoliko vino nije saobrazno, ili ima drugi nedostatak koji utiče na kvalitet isporučenog vina u okviru paketa.
            Fruškogorski vinogradi će obavestiti korisnika pisanim ili elektronskim putem u najkraćem roku, ali ne dužem od 8 (osam) dana o ishodu izjavljene reklamacije. U slučaju usvojene reklamacije, Prodavac će izvršiti povrat novca
            kupcu u najkraćem roku a najduže u roku od 14 dana od dana usvojene reklamacije i vraćanja proizvoda, isključivo putem uplate iznosa na tekući račun kupca. <br/><br/>

            <h2>6) ZAŠTITA POVERLJIVIH PODATAKA O TRANSAKCIJI</h2>
            Prilikom unošenja podataka o platnoj karitici, poverljive informacije se prenose putem javne mreže u zaštićenoj (kriptovanoj) formi upotrebom SSL protokola i PKI sistema, kao trenutno najsavremenije kriptografske tehnologije. <br/><br/>
            Sigurnost podataka prilikom kupovine, garantuje procesor platnih kartica, Banca Intesa a.d. Beograd, pa se tako kompletni proces naplate obavlja na stranicama banke. Niti jednog trenutka podaci o platnoj kartici nisu dostupni našem sistemu. <br/><br/>

            <h2>7) POVRAĆAJ SRESTAVA</h2>
            U slučaju vraćanja robe i povraćaja sredstava kupcu koji je prethodno platio nekom od platnih kartica, delimično ili u celosti, a bez obzira na razlog vraćanja, Fruškogorski Vinogradi d.o.o. Banoštor je u obavezi da povraćaj vrši isključivo preko VISA,
            EC/MC, Maestro, Amex i Dina metoda plaćanja, što znači da će banka na zahtev prodavca obaviti povraćaj sredstava na račun korisnika kartice. <br/><br/>

            <h2>8) IZJAVA O PDV-U</h2>
            Fruškogorski vinogradi d.o.o. Banoštor je u sistemu PDV-a. <br/><br/>

            PDV je uračunat u cenu i nema skrivenih troškova.
        </div>
    );
}

export default TermsAndConditions;