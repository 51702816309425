const breakpoints = {
    xxxsm: '360px',
    xxsm: '428px',
    xsm: '600px',
    sm: '667px',
    md: '878px',
    lg: '1024px',
    xxl: '1260px',
    xxxl: '1376px'
  };

  export default breakpoints;