import styled, { keyframes } from "styled-components";

const shimmer = keyframes`
    100% {
        transform: translateX(100%);
    }
`;

const ImageSkeletonStyled = styled.div<{loaded: boolean}>`
    width: 100%;
    height: 100%;
    display: inline-block;
    position: relative;
    overflow: hidden;
    background-color: #f6f7f8;

    display: ${(props) => props.loaded ? `none` : `block`};

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
      animation: ${shimmer} 1.3s infinite;
      content: '';
    }
`;

interface ImageSkeletonProps {
    loaded: boolean;
}
const ImageSkeleton = ({loaded}: ImageSkeletonProps) => {
    return <ImageSkeletonStyled loaded={loaded}/>;
}

export default ImageSkeleton;